import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { AutoTooltip, CommentsBlock, ModalWindow } from "lib/common";
import { FaCommentDots } from "react-icons/fa";
import { FiAlertTriangle, FiInfo } from "react-icons/fi";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Constants from "const/Constants";
import DataConstants from "const/DataConstants";
import React from "react";
import Utils from "utils/Utils";
import moment from "moment";

const { EMPTY_PLACEHOLDER, DATETIME_FORMATS: { DATE_TEXT } } = Constants;

const {
  TRANSACTION_TYPES: { WITHDRAW },
  COMMENT_TARGET_TYPES: { TRANSACTIONS }
} = DataConstants;

const CommentsWindow = ({ type: commentsType = TRANSACTIONS, transaction = {}, onClose }) => {
  const { uiTexts } = useSelector(getTextsData);

  const { id: transactionId, amount, currency, type, timestamp, description } = transaction;

  const withdrawTransaction = type === WITHDRAW;

  return (
    <ModalWindow
      enableDefaultEnter
      className={Css.commentsWindow}
      config={{ headerText: uiTexts.comments }}
      iconComponent={FaCommentDots}
      onClose={onClose}>
      <div className={Css.transactionInfo}>
        <div
          className={`${Css.amount} ${CommonCss.coloredNumber}`}
          data-negative={withdrawTransaction ? "" : undefined}>
          {amount ? Utils.toMoneyString(amount, currency) : EMPTY_PLACEHOLDER}
        </div>
        <div className={Css.date}>
          {timestamp ? moment.utc(timestamp).format(DATE_TEXT) : ""}
        </div>
        <div className={Css.description}>
          <AutoTooltip className={Css.tooltip} active={!!description}>
            {description ? <FiInfo /> : <FiAlertTriangle className={CommonCss.warningText} />}
            <span>{description || uiTexts.noBankDescriptionOrMemo}</span>
          </AutoTooltip>
        </div>
      </div>
      <CommentsBlock
        type={commentsType}
        id={transactionId}
        className={Css.commentsBlock} />
    </ModalWindow>
  );
};

export default CommentsWindow;
